<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ $t('general.institutions') }}</li>
            <li>{{ $t('institution.list.add_company') }}</li>
          </ul>
        </nav>
      </div>

      <div class="card" v-if="loading">
        <default-loading></default-loading>
      </div>
      <template v-else>

        <div class="card">
          <div v-for="error in errorMessages" v-if="errorMessages" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color:red;">
              <i class="uil-exclamation-triangle"></i> {{ error }}
            </p>
          </div>
        </div>
        <div class="card row">
          <form class="regular-form" action="">
            <div class="uk-grid uk-child-width-1-2 uk-padding" uk-grid>

              <div>
                <h4><i class="uil-user-circle"></i> {{ $t('institution.information') }}</h4>
                <div class="form-group row mb-3">
                  <label class="col-md-12 col-form-label" for="logo">{{ $t('institution.avatar') }}<span
                      class="required">*</span></label>
                  <div class="col-md-12">
                    <div class="position-relative d-flex justify-center justify-content-center align-items-center">
                      <img class="rounded img-fluid logoarea" src="@/assets/images/no-photo.png" alt="">
                      <input type="file" class="d-none" id="avatar" @change="processFile($event)" accept="image/*">
                      <button @click="inputClick()" type="button" class="btn btn-sm btn-outline-primary ml-2">
                        {{ $t('general.image_change') }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="surname">{{ $t('institution.list.company_name') }}<span
                        class="required">*</span></label>
                    <input type="text" class="form-control" id="surname" name="company_name" placeholder=""
                      v-model="form.name" required="">
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="start_date">{{ $t('institution.contract_start_date') }}<span
                        class="required">*</span></label>
                    <input type="date" class="form-control" id="start_date" name="start_date" placeholder=""
                      v-model="form.start_date" required="">
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="end_date">{{ $t('institution.list.contract_expired_date') }}<span
                        class="required">*</span></label>
                    <input type="date" class="form-control" id="end_date" :min="form.start_date" name="end_date"
                      placeholder="" v-model="form.end_date" required="">
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-form-label" for="domain">{{ $t('institution.domain_name') }}<span
                        class="required">*</span></label>
                    <input type="text" class="form-control" id="domain" name="domain" placeholder=""
                      v-model="form.domain" required="">
                  </div>
                </div>

                <div class="form-group row domain uk-first-column">
                  <div class="col-md-12">
                    <label class="col-form-label">{{ $t('institution.alias_name') }}<span
                        class="required">*</span></label>
                    <div class="d-flex">
                      <input type="text" id="alias" name="alias" placeholder="" v-model="form.alias"
                        class="form-control">
                      <span>.softinya.com</span>
                    </div>
                  </div>
                </div>

                <div class="form-group row" v-if="isAdmin">
                  <div class="col-md-12">
                    <label class="col-form-label" for="domain">Anzera Eğitim Pini</label>
                    <input type="text" class="form-control" v-model="form.pin" placeholder="" required="">
                  </div>
                </div>
              </div>
              <div>
                <h4><i class="uil-books"></i> Satın Alınan Katalog Eğitimleri</h4>
                <div class="section-header pb-0 mb-4">
                  <div class="section-header-left">
                    <form action="" @submit.prevent="applyFilter">
                      <input v-model="searchQuery" :placeholder="$t('home.Education_shearing')" class="mt-3" type="text"
                        @input="isTyping = true">
                    </form>
                  </div>
                  <div class="section-header-right">
                    <select v-model="order_by" class="ml-3 mb-0" @change="applyFilter">
                      <option value="name">{{ $t('filter.Filter_by_name') }}</option>
                      <option value="training_type_id">{{ $t('filter.Filter_by_category') }}</option>
                      <option value="created_at">{{ $t('filter.Filter_by_date') }}</option>
                    </select>
                  </div>
                </div>
                <div class="uk-width-2-3@m" style="width: 100%;">
                  <default-loading v-if="catalog_loading">
                  </default-loading>
                  <template v-else>
                    <div class="card rounded uk-card-hover uk-card-small">
                      <div class="p-3 d-flex align-items-center justify-content-between">
                        {{ items ? items.total : 0 }} Katalog Eğitimi Görüntüleniyor
                        <input id="user-admin" v-model="training_purchase_registration" type="checkbox" value="1"
                          style="width: 13px; margin-right: 10px;margin-top: 10px;" />
                        Satın Alındı Olarak Yap
                        <!-- <button class="uk-button uk-button-default">Tümünü Seç</button> -->
                        <button type="button" class="btn btn-sm btn-soft-secondary mr-2" @click="allUserToggle()">
                          <i class="uil-check-square"></i> {{ selectAllTrainingText }}
                        </button>
                        <!-- <h5 class="mb-0"> {{ selected_type.name }}</h5> <span> {{
                          items ? items.total : 0
                        }} {{ $t('education.education') }}</span> -->
                      </div>
                      <template v-for="(subItem, index) in items.data" v-if="items && items.data && items.data.length">
                        <hr class="m-0">
                        <div class="uk-grid-small p-4" uk-grid>
                          <th>
                            <input v-model="form.self_registrable_training_ids"
                              :id="'notification_checkbox_' + subItem.id" :value="subItem.id"
                              style="width: 15px; margin: 10px 10px 10px 0" type="checkbox" />
                          </th>
                          <div class="uk-width-1-3@m">
                            <img :src="subItem.cover_image_link" alt="" class="  rounded">
                          </div>
                          <div class="uk-width-expand">
                            <a>
                              <h5 class="mb-2"> {{ subItem.name }}

                              </h5>
                              <p class="mb-0 uk-text-small mt-3">
                                <span class="mr-3 bg-light p-2 mt-1"> {{
                $t('education.section_count', { 'number': subItem.training_content_count }) + ' / ' +
                $t('education.content_count', { 'number': subItem.training_content_count })
              }}</span>
                              </p>
                              <p class="uk-text-small mb-2 mt-4">{{ subItem.description }}</p>
                            </a>
                          </div>
                        </div>
                      </template>
                    </div>
                    <Pagination v-if="items && items.data && items.last_page !== 1" :page.sync="pageProxy"
                      :paginate="items"></Pagination>
                  </template>
                </div>
              </div>
            </div>
            <div class="uk-card-footer pt-0">
              <div class="float-right">
                <button @click="saveForm()" class="btn btn-primary btn-sm mt-2" type="button">
                  <i class="uil-check"></i> {{ $t('general.save') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import module, { BASE_URL, MODULE_NAME, ERROR, CREATE_ITEM } from "@/core/services/store/company.module";
import store from '@/core/services'
import moment from "moment";
import $ from "jquery";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import { GET_ITEMS, ITEMS, LOADING, DELETE_ITEM_BY_ID } from "@/core/services/store/REST.module";
const _TRAINING_KIND_MODULE = MODULE_TRAINING_KIND;
import Pagination from "@/view/components/dataTable/Pagination";
import { mapGetters } from 'vuex';
import training_kind_module, {
  BASE_URL as TRAINING_KIND_URL,
  GET_ITEMS as GET_TRAINING_KINDS,
  MODULE_NAME as MODULE_TRAINING_KIND,
  ITEMS as TRAINING_KINDS
} from "@/core/services/store/training_kind.module";

export default {
  name: "InstitutionAdd",
  components: {
    DefaultLoading,
    Pagination
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(_TRAINING_KIND_MODULE, training_kind_module)
    registerStoreModule(MODULE_NAME, module)
  },
  data() {
    return {
      training_purchase_registration: false,
      selectAllTrainingText: "Tümünü Seç",
      form: {
        type: 'institution',
        name: '',
        domain: '',
        alias: '',
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add('1', 'year').format('YYYY-MM-DD'),
        avatar: '',
        pin: '',
        selected_type: {
          id: null,
          name: this.$t('education.all_categories')
        },
        self_registrable_training_ids: [],
      },
      search: null,
      searchQuery: "",
      isTyping: false,
      searchResult: [],
      options: [0],
      kinds: [0],
      order_by: 'name',
      sort: 'desc',
      restUrl: 'api/training',
      educationTypes: [],
      selected_type: {
        id: null,
        name: this.$t('education.all_categories')
      },
      filters: {
        page: 1,
        search: '',
        training_type_id: 0,
        training_kind_id: 0,
        admin_page: true
      },

      successMessage: null,
      errorMessages: [],
      selected_type_name: null,
      trainingCategoryUrl: 'api/training-type',
      page: 1,
      allTraining: false

    }
  },
  computed: {
    ...mapGetters({
      items: ITEMS,
      catalog_loading: LOADING
    }),
    training_kinds: {
      get() {
        return store.getters[_TRAINING_KIND_MODULE + '/' + TRAINING_KINDS]
      },
      set(value) {
      }
    },
    formattedTrainingKind() {
      let results = [];
      if (this.training_kinds === null || !("data" in this.training_kinds)) return [];
      this.training_kinds.data.forEach(item => {
        results.push({
          id: item.id,
          value: item.id,
          name: item.name
        });
      });
      return results;
    },
    pageProxy: {
      set(value) {
        this.page = value
        this.applyFilter()
      },
      get() {
        return this.page;
      }
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    },
    loading() {
      return store.getters[MODULE_NAME + '/' + LOADING]
    },
  },
  methods: {
    allUserToggle() {
      if (this.selectAllTrainingText === 'Tümünü Seç') {
        this.items.data.forEach((training) => {
          let isSelfTraining = false
          this.form['self_registrable_training_ids']?.every((selfTraining) => {
            if (selfTraining === training.id) {
              isSelfTraining = true
              return true
            } else {
              return true
            }
          });
          if (!isSelfTraining)
            this.form['self_registrable_training_ids'].push(training.id)
        });
        this.allTraining = true;
        this.selectAllTrainingText = 'Tümünü Kaldır';
      } else {
        this.items.data.forEach((training) => {
          let isSelfTraining = false
          let selfIndex = 0;
          this.form['self_registrable_training_ids']?.every((selfTraining, index) => {
            if (selfTraining === training.id) {
              isSelfTraining = true
              selfIndex = index;
              return true
            } else {
              return true
            }
          });
          if (isSelfTraining)
            this.form['self_registrable_training_ids'].splice(selfIndex, 1);
        });
        // this.form['self_registrable_training_ids'] = [];
        this.allTraining = false
        this.selectAllTrainingText = 'Tümünü Seç';
      }
    },
    saveForm() {
      let form = this.form;
      this.errorMessages = [];
      if (form.avatar === null || form.avatar === "") {
        this.errorMessages.push(this.$t('general.required_institution_logo'));
        this.scrollToTop();
        return false;
      }
      if (form.name.length === 0) {
        this.errorMessages.push(this.$t('company.required.name'));
        this.scrollToTop();
        return false;
      }
      if (form.start_date.length === 0) {
        this.errorMessages.push(this.$t('company.required.start_date'));
        this.scrollToTop();
        return false;
      }
      if (form.end_date.length === 0) {
        this.errorMessages.push(this.$t('company.required.end_date'));
        this.scrollToTop();
        return false;
      }
      if (form.domain.length === 0) {
        this.errorMessages.push(this.$t('company.required.domain'));
        this.scrollToTop();
        return false;
      }
      if (form.alias.length === 0) {
        this.errorMessages.push(this.$t('company.required.alias'));
        this.scrollToTop();
        return false;
      }

      let formData = new FormData();

      formData.append('type', form.type);
      formData.append('name', form.name);
      formData.append('domain', form.domain);
      formData.append('alias', form.alias);
      formData.append('start_date', form.start_date);
      formData.append('end_date', form.end_date);
      formData.append('avatar', form.avatar);
      formData.append('pin', form.pin);
      formData.append("training_purchase_registration", this.training_purchase_registration ? 1 : 0);
      // if (this.allTraining) {
      //   formData.append('self_registrable_training_ids', 'all');
      // } else {
      for (let i = 0; i < form.self_registrable_training_ids.length; i++) {
        formData.append('self_registrable_training_ids[]', form.self_registrable_training_ids[i]);
        // }
      }
      store.dispatch(MODULE_NAME + '/' + CREATE_ITEM, {
        url: BASE_URL,
        contents: formData
      }).then(() => {
        if (!this.error) {
          this.$router.push({ name: 'company.institutions.list', params: { 'succesMessage': this.$t('general.update_success') } })
        } else this.errorMessages.push(this.error)
      })
    },
    processFile(event) {
      let imageInput = event.target.files[0];
      if (!imageInput.type.includes('image/')) {
        this.errorMessages.push(this.$t('general.required_institution_logo'));
        return false
      }
      this.form.avatar = imageInput;
      let blob = URL.createObjectURL(imageInput);
      let imageHolder = $('.logoarea');
      imageHolder.attr('src', blob);
    },
    inputClick() {
      $('#avatar').click();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    applyFilter() {
      this.$set(this.filters, 'page', this.page)
      // this.$set(this.filters, 'training_type_id', this.selected_type.id)
      if (!(this.options.length === 1 && this.options[0] === 0)) {
        this.$set(this.filters, 'training_type_id[]', this.options)
      }
      if (!(this.kinds.length === 1 && this.kinds[0] === 0)) {
        this.$set(this.filters, 'training_kind_id[]', this.kinds)
      }
      this.$set(this.filters, 'search', this.search)
      this.$set(this.filters, 'order_by', this.order_by)
      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        showLoading: true,
        filters: this.filters,
      }).then((response) => {
        // if (response && this.allTraining) {
        //   this.items.data.forEach(training => this.form['self_registrable_training_ids'].push(training.id));
        // }
      })
    },
    searchUser: function (searchQuery) {
      let self = this;
      this.$set(this.filters, 'page', this.page)
      // this.$set(this.filters, 'training_type_id', this.selected_type.id)
      this.$set(this.filters, 'training_type_id', this.options)
      this.$set(this.filters, 'training_kind_id', this.kinds)
      this.$set(this.filters, 'search', this.searchQuery)
      this.$set(this.filters, 'order_by', this.order_by)
      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        filters: this.filters,
        showLoading: true,
      })
    },
  },
  watch: {
    searchQuery: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.searchUser(this.searchQuery);
      }
    }
  },
  mounted() {
    this.selectAllTrainingText = 'Tümünü Seç';
    if (!this.CatalogTrainingVisible) {
      this.$router.push({ name: 'company.dashboard' })
    }
    this.selected_type_name = this.$t('education.all_categories')
    let self = this;

    store.dispatch(_TRAINING_KIND_MODULE + '/' + GET_TRAINING_KINDS, {
      url: TRAINING_KIND_URL
    });

    this.$store.dispatch(GET_ITEMS, {
      url: this.trainingCategoryUrl,
      acceptPromise: true,
    }).then(
      (response) => {
        if (response.status) {
          self.educationTypes = response.data;
          self.educationTypes.unshift({ id: 0, name: this.$t('education.all_categories') });
        }
      }
    );
    this.applyFilter()
  }
};
</script>
<style scoped>
.uk-alert {
  margin: 0;
}
</style>